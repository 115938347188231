export const PricingItems = ({ isHomepage }: any) => {
  const pricingItems = {
    lekarskeVysetrenia: [
      {
        name: 'Prehliadka v súvislosti so spôsobilosťou na výkon práce (vstupná, výstupná alebo periodická) na žiadosť zamestnávateľa alebo zamestnanca, pre SBS, odvod brancov',
        price: '30',
      },
      {
        name: 'Vyšetrenie a vystavenie spôsobilosti - kurzy, školenia, na štúdium, šport. súťaž, a.i.',
        price: '15',
      },
      {
        name: 'Vyšetrenie a vystavenie zdravotného preukazu u pracovníkov, ktorí vykonávajú epidemiologicky závažné činnosti',
        price: '20',
      },
      {
        name: 'Vyšetrenie a  vystavenie  spôsobilosti viesť motorové vozidlo (vodičský preukaz)',
        price: '35',
      },
      {
        name: 'Vyšetrenie a vystavenie spôsobilosti držať a nosiť zbraň, strelivo (zbrojný preukaz)',
        price: '45',
      },
    ],
    potvrdenia: [
      {
        name: 'Vystavenie tlačiva dočasnej práceneschopnosti (DPN), ktoré nehradí Soc. poisťovňa',
        price: '3',
      },
      {
        name: 'Vystavenie tlačiva trvania DPN (TDPN) pre ÚPSVaR pre občana v hmotnej núdzi',
        price: 'typ I 1€ <br/> typ II 7',
      },
      { name: 'Vystavenie nálezu pre komerčné účely, poisťovne', price: '40' },
      {
        name: 'Vystavenie nálezu pre orgány činné v občianskom, súdnom alebo trestnom konaní',
        price: '20',
      },
      {
        name: 'Vystavenie tlačiva na sociálne účely, odkázanosť na sociálnu službu, domov dôchodcov, kompenzácie (urýchlene do 3 dní)',
        price: '25',
      },
      {
        name: 'Vystavenie výpisu zo zdravotnej dokumentácie na účely súvisiace s poskytovaním zdravotnej starostlivosti',
        price: '2',
      },
      {
        name: 'Vystavenie výpisu zo zdravotnej dokumentácie na iné účely',
        price: '15',
      },
      {
        name: 'Vystavenie lekárskeho potvrdenia na administratívne účely na vlastnú žiadosť',
        price: '3',
      },
    ],
    vykony: [
      {
        name: 'Preventívna prehliadka  (mimo úhrady ZP)',
        price: '40',
      },
      {
        name: 'Lekárske vyšetrenie mimo rozsahu preventívnej prehliadky  (mimo úhrady ZP)',
        price: '15',
      },
      { name: 'EKG alebo ABI alebo FOB (mimo úhrady ZP)', price: '7' },
      { name: 'CRP (mimo úhrady ZP)', price: '7' },
      { name: 'Vyšetrenie kapilárnej glykémie (mimo úhrady ZP)', price: '1' },
      {
        name: 'Ambulantné 24 hodinové monitorovanie krvného tlaku (mimo úhrady ZP)',
        price: '20',
      },
      { name: 'Výplach ucha jednostranne (mimo úhrady ZP)', price: '3' },
      { name: 'Kopírovanie ČB - 1 str. A4', price: '0.3' },
    ],
  };
  return (
    <section className={`pricing-table ${isHomepage ? '' : 'section'}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="single-table">
              <div className="table-head">
                <div className="icon">
                  <i className="icofont icofont-doctor"></i>
                </div>
                <h4 className="title">LEKÁRSKE VYŠETRENIA</h4>
              </div>

              <ul className="table-list">
                {pricingItems.lekarskeVysetrenia.map((item) => (
                  <li className="d-flex justify-content-between">
                    <div>{item.name}</div>
                    <div style={{ float: 'right' }}>
                      <b>{item.price}€</b>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-12">
            <div className="single-table">
              <div className="table-head">
                <div className="icon">
                  <i className="icofont icofont-list"></i>
                </div>
                <h4 className="title">Vystavenie potvrdení</h4>
              </div>

              <ul className="table-list">
                {pricingItems.potvrdenia.map((item) => (
                  <li className="d-flex justify-content-between">
                    <div>{item.name}</div>
                    <div style={{ float: 'right' }}>
                      <b dangerouslySetInnerHTML={{ __html: `${item.price}€` }} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-12">
            <div className="single-table">
              <div className="table-head">
                <div className="icon">
                  <i className="icofont-euro"></i>
                </div>
                <h4 className="title">Výkon mimo úhrady ZP</h4>
              </div>

              <ul className="table-list">
                {pricingItems.vykony.map((item) => (
                  <li className="d-flex justify-content-between">
                    <div>{item.name}</div>
                    <div style={{ float: 'right' }}>
                      <b>{item.price}€</b>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
